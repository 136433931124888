import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export const LycianWayPage = () => {
    
    const languages = useSelector((state) => state.languages.currentLanguages);
    const history = useNavigate();
    return(
        <>
        <div className="back-button-cover">
          <button className="back-button" onClick={() => history(-1)}>
            <IoIosArrowBack size={"25px"} /> {languages==="RU"? 'Назад':"Back"} 
          </button>
        </div>
   
        <h2 >Подготовка к походу</h2>
        <span>
        Для этого путешествия нам нужно было тщательно подготовиться, ведь на тропе нас ждут разные условия — от горных перевалов до прибрежных участков. Мы подобрали надежное снаряжение из ассортимента нашего магазина-проката Adjarapeak, и вот несколько вещей, которые стали незаменимыми для нашего путешествия:
        </span>
       <ol>
        <li>
        Рюкзаки — удобные и прочные рюкзаки с возможностью распределения нагрузки.
        </li>
        <li>
        Палатка и спальники — легкие, но теплые и удобные для ночевок под открытым небом.

        </li>
        <li>
        Обувь и одежда — влагостойкие ботинки, термобелье, куртки и штормовки для защиты от непогоды.        </li>
        <li>
        GPS — хоть тропа и размечена, мы также решили подстраховаться и взяли навигацию.
        </li>
        <li>
       Аптечка — обязательно возьмите с собой набор для оказания первой помощи, особенно для защиты от солнца и лечения мелких травм.
        </li>
        <li>Не забывайте о запасах воды и питания. В некоторых частях тропы нет воды и магазинов, так что возьмите фильтры и сушеную еду.</li>
       </ol>

       <h2 >Онлайн-трансляция нашего путешествия       </h2>
       <span>
Хотите следить за нашим маршрутом в реальном времени? Мы организовали онлайн-трансляцию с отображением нашего передвижения на карте! Это позволит вам увидеть, какой путь мы прошли, где остановились. Такой формат даст вам возможность буквально «идти» вместе с нами и наблюдать, как мы преодолеваем трудности Ликийской тропы.
</span>
<br/>
<a href="https://eur-share.explore.garmin.com/adjarapeak" target="_blank" rel="noopener noreferrer">

<span  style={{
  backgroundColor: '#f9c74f', /* светло-оранжевый фон */
  color: '#333', /* темный цвет текста */
  padding: '5px 10px', /* внутренние отступы */
  borderRadius: '5px', /* закругленные углы */
  fontWeight: 'bold', /* жирный шрифт */

}} >Следить за перемещениями </span>


</a>
<p className="routes-item-page" style={{ color: 'orange',marginTop:'10px' }}>
  Пароль для входа: LyciawithAdjpeak
</p>

<p>Наша цель — вдохновить больше людей отправляться на природу, открывать для себя новые маршруты и понимать, насколько важно качественное снаряжение. Мы приглашаем вас в это виртуальное путешествие вместе с Adjarapeak, и если наш опыт поможет вам собрать свой рюкзак и отправиться в дорогу, значит, мы достигли своей цели!</p>
<p>Увидимся на Ликийской тропе!</p>
      </>
    )
    }